"use strict";
/* ============
 * vuex-persistedstate
 * ============
 *
 * Persist Vuex state with localStorage. https://github.com/robinvdvleuten/vuex-persistedstate
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var vuex_persistedstate_1 = require("vuex-persistedstate");
var Cookies = require("js-cookie");
exports.vuexUser = vuex_persistedstate_1.default({
    key: 'user',
    paths: [
        'user.userInfo',
    ]
});
exports.vuexToken = vuex_persistedstate_1.default({
    key: 'token_id',
    paths: ['auth.token_id'],
    storage: {
        getItem: function (key) { return Cookies.get(key); },
        setItem: function (key, value) { return Cookies.set(key, value, { domain: "." + window.location.hostname, expires: 375 }); },
        removeItem: function (key) { return Cookies.remove(key); }
    }
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
/* ============
 * Plugins
 * ============ */
require("./plugins/vuex");
require("./plugins/axios");
var vue_router_1 = require("./plugins/vue-router");
require("./plugins/vuex-router-sync");
var store_1 = require("./store");
var App_vue_1 = require("@/App.vue");
// main sass
require("./assets/css/main.sass");
vue_1.default.config.productionTip = false;
// tslint:disable-next-line:no-unused-expression
new vue_1.default({
    el: '#app',
    router: vue_router_1.router,
    store: store_1.default,
    render: function (h) { return h(App_vue_1.default); }
});

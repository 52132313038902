"use strict";
/* ============
 * Vuex Router Sync
 * ============
 *
 * Sync vue-router's current $route as part of vuex store's state.
 *
 * https://github.com/vuejs/vuex-router-sync
 */
Object.defineProperty(exports, "__esModule", { value: true });
var VuexRouterSync = require("vuex-router-sync");
var store_1 = require("../store");
var vue_router_1 = require("./vue-router");
VuexRouterSync.sync(store_1.default, vue_router_1.router);

"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
exports.default = vue_1.default.extend({
    data: function () { return ({
        numero: null
    }); },
    computed: __assign({}, vuex_1.mapState('modal', ['tipoNumeroPerModal']), { numeroVaBene: function () {
            return Boolean(this.numero) && /[A-Z][0-9][0-9]$/i.test(this.numero);
        } }),
    methods: __assign({}, vuex_1.mapActions('modal', ['showModal']), vuex_1.mapActions('numeriAttuali', ['postNumeriAttuali']), { salvaNumero: function () {
            var _a;
            this.postNumeriAttuali((_a = {},
                _a[this.tipoNumeroPerModal] = this.numero,
                _a));
            this.showModal(null);
        } })
});

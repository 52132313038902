"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
exports.default = vue_1.default.extend({
    data: function () { return ({
        slideAttuale: 1,
        slides: [
            {
                img: '/static/img/_slide1_.png'
            },
            {
                img: '/static/img/Messaggio QR.png'
            },
            {
                img: '/static/img/_slide2_.png'
            },
            {
                img: '/static/img/Messaggio QR.png'
            },
            {
                img: '/static/img/_slide3_.png'
            },
            {
                img: '/static/img/Messaggio QR.png'
            }
        ]
    }); },
    computed: __assign({}, vuex_1.mapState('numeriAttuali', ['numeroAttualeAbbonamenti', 'numeroAttualeTessereDelTifoso'])),
    watch: {
        numeroAttualeAbbonamenti: function (newValue, oldValue) {
            if (newValue !== oldValue)
                document.getElementById('audio').play();
        },
        numeroAttualeTessereDelTifoso: function (newValue, oldValue) {
            if (newValue !== oldValue)
                document.getElementById('audio').play();
        }
    },
    methods: {
        prossimaSlide: function () {
            if (this.slideAttuale === this.slides.length)
                this.selezionaSlide(1);
            else
                this.selezionaSlide(this.slideAttuale + 1);
        },
        selezionaSlide: function (slide) {
            this.slideAttuale = slide;
        }
    },
    created: function () {
        setInterval(this.prossimaSlide, 20000);
    }
});

"use strict";
/* ============
 * axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var axios_1 = require("axios");
// config axios
axios_1.default.defaults.baseURL = '/';
axios_1.default.defaults.headers.common.Accept = 'application/json';
axios_1.default.defaults.xsrfCookieName = 'csrftoken';
axios_1.default.defaults.xsrfHeaderName = 'X-CSRFToken';
// Bind Axios to Vue.
vue_1.default.axios = axios_1.default;
Object.defineProperty(vue_1.default.prototype, 'axios', {
    get: function () {
        return axios_1.default;
    }
});

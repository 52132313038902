"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var costanti_1 = require("../costanti");
exports.default = {
    namespaced: true,
    state: {
        token_id: '',
        loginSuccessful: false
    },
    getters: {
        isAuthenticated: function (state) { return state.token_id !== ''; }
    },
    mutations: {
        LOGIN: function (state, token) {
            // token gets set in state and local storage
            state.token_id = token;
            // sets axios to use token by default for every call
            vue_1.default.axios.defaults.headers.common.Authorization = "Token " + token;
            //
            state.loginSuccessful = true;
        },
        LOGOUT: function (state) {
            state.token_id = '';
            vue_1.default.axios.defaults.headers.common.Authorization = '';
        },
        SET_LOGIN_SUCCESSFUL: function (state, value) { return (state.loginSuccessful = value); }
    },
    actions: {
        login: function (_a, dispatchParams) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var response, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, vue_1.default.axios.post('/rest-auth/login/', dispatchParams)
                                //
                            ];
                        case 1:
                            response = _b.sent();
                            //
                            commit('LOGIN', response.data.key);
                            // sync user
                            dispatch('syncUser');
                            // show success notification
                            dispatch('showWelcomeNotification');
                            return [3 /*break*/, 3];
                        case 2:
                            err_1 = _b.sent();
                            commit('LOGOUT');
                            console.error(err_1);
                            // show success notification
                            dispatch('modal/showNotification', {
                                name: costanti_1.NOTIFICA_ERRORE,
                                title: "Errore durante l'autenticazione"
                            }, { root: true });
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        logout: function (_a) {
            var commit = _a.commit, dispatch = _a.dispatch, getters = _a.getters;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    commit('LOGOUT');
                    // remove user data from state and caches
                    dispatch('user/resetEverything', null, { root: true });
                    vue_1.default.axios.post('/rest-auth/logout/');
                    return [2 /*return*/];
                });
            });
        },
        checkForSavedToken: function (_a) {
            var getters = _a.getters, state = _a.state, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    // grabs user token if he was signed in already,
                    // and sets axios to use token for every call
                    if (getters.isAuthenticated) {
                        vue_1.default.axios.defaults.headers.common.Authorization = "Token " + state.token_id;
                        // load user info
                        dispatch('user/fetchUserInfo', {}, { root: true });
                    }
                    return [2 /*return*/];
                });
            });
        },
        syncUser: function (_a) {
            var dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    // load user info
                    dispatch('user/fetchUserInfo', {}, { root: true });
                    return [2 /*return*/];
                });
            });
        },
        showWelcomeNotification: function (_a) {
            var dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var userInfo;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, vue_1.default.axios.get('/api/user.json')];
                        case 1:
                            userInfo = (_b.sent()).data[0];
                            // notifica
                            dispatch('modal/showNotification', {
                                name: costanti_1.NOTIFICA_SUCCESSO,
                                title: "Ciao " + userInfo.user.first_name + " " + userInfo.user.last_name + "!"
                            }, { root: true });
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};

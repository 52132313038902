"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var costanti_1 = require("../../costanti");
exports.default = vue_1.default.extend({
    props: ['notification'],
    computed: {
        classObject: function () {
            switch (this.notification.name) {
                case costanti_1.NOTIFICA_SUCCESSO:
                    return 'toast-success';
                case costanti_1.NOTIFICA_ERRORE:
                    return 'toast-error';
                default:
                    return 'toast-primary';
            }
        }
    }
});

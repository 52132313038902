"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var routes_1 = require("../routes");
var store_1 = require("@/store");
vue_1.default.use(vue_router_1.default);
exports.router = new vue_router_1.default({
    routes: routes_1.default,
    mode: 'history',
    scrollBehavior: function (to, from, savedPosition) { return savedPosition || { x: 0, y: 0 }; }
});
exports.router.beforeEach(function (to, from, next) {
    // console.log('cahnging route');
    if ((to.matched.some(function (m) { return m.meta.requiresAuth; }) && !store_1.default.getters['auth/isAuthenticated'])) {
        /*
         * If the user is not authenticated and visits
         * a page that requires authentication
         * si ritorna alla pagina iniziale, poco fare i furbi
         */
        next({ name: 'Login' });
    }
    else {
        next();
    }
});
vue_1.default.router = exports.router;

"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
exports.default = vue_1.default.extend({
    data: function () { return ({
        username: '',
        password: ''
    }); },
    computed: __assign({ loginSuccessful: {
            get: function () {
                return this.$store.state.auth.loginSuccessful;
            },
            set: function (value) {
                this.SET_LOGIN_SUCCESSFUL(value);
            }
        } }, vuex_1.mapGetters('auth', ['isAuthenticated'])
    /* statoPulsanteEntra(): object {
        return {disabled: this.username.length < 3 || this.password.length < 3}
    } */
    ),
    methods: __assign({}, vuex_1.mapActions('modal', ['showModal']), vuex_1.mapMutations('auth', ['SET_LOGIN_SUCCESSFUL']), { login: function () {
            this.$store.dispatch('auth/login', {
                username: this.username,
                password: this.password
            });
        } }),
    watch: {
        loginSuccessful: function (newValue) {
            var _this = this;
            if (newValue) {
                setTimeout(function () {
                    _this.$router.push({ name: 'GestioneAbbonamenti' });
                }, 1000);
            }
        }
    }
});

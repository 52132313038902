"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    namespaced: true,
    state: {
        activeNotification: null,
        activeModal: null,
        tipoNumeroPerModal: null
    },
    mutations: {
        SET_ACTIVE_MODAL: function (state, payload) {
            state.activeModal = payload;
            // cleans any error message when modal changes
            state.loginError = '';
            state.signupError = '';
        },
        // == Notifiche ==
        PUSH_NOTIFICATION: function (state, payload) { return (state.activeNotification = payload); },
        REMOVE_NOTIFICATION: function (state, payload) {
            // se l evento arriva dalla notifica che viene mostrata in questo momento
            if (state.activeNotification === payload)
                state.activeNotification = null;
        },
        SET_TIPO_NUMERO: function (state, payload) { return (state.tipoNumeroPerModal = payload); },
    },
    actions: {
        showModal: function (_a, modalToShow) {
            var commit = _a.commit;
            commit('SET_ACTIVE_MODAL', modalToShow);
        },
        showNotification: function (_a, dispatchParams) {
            var commit = _a.commit;
            commit('PUSH_NOTIFICATION', dispatchParams);
            setTimeout(function () {
                commit('REMOVE_NOTIFICATION', dispatchParams);
            }, 3500);
        }
    }
};

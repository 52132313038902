"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var AbstractPaginaGestione_1 = require("@/components/AbstractPaginaGestione");
var costanti_1 = require("@/costanti");
exports.default = vue_1.default.extend({
    components: { AbstractPaginaGestione: AbstractPaginaGestione_1.default },
    data: function () { return ({
        NUMERO_TESSERE_DEL_TIFOSO: costanti_1.NUMERO_TESSERE_DEL_TIFOSO
    }); }
});

"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
exports.default = vue_1.default.extend({
    computed: __assign({}, vuex_1.mapState('numeriAttuali', ['numeroAttualeAbbonamenti', 'numeroAttualeTessereDelTifoso']))
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Navbar_vue_1 = require("@/components/Navbar.vue");
var Footer_vue_1 = require("@/components/Footer.vue");
exports.default = [
    {
        path: '/',
        name: 'HomeHorizontal',
        components: {
            // navbar: Navbar,
            default: function () { return Promise.resolve().then(function () { return require('@/views/HomeHorizontal.vue'); }); },
        }
    },
    {
        path: '/qr',
        name: 'Home',
        components: {
            navbar: Navbar_vue_1.default,
            default: function () { return Promise.resolve().then(function () { return require('@/views/HomeVertical.vue'); }); },
            footer: Footer_vue_1.default
        }
    },
    {
        path: '/login',
        name: 'Login',
        components: {
            navbar: Navbar_vue_1.default,
            default: function () { return Promise.resolve().then(function () { return require('@/views/Login.vue'); }); },
            footer: Footer_vue_1.default
        }
    },
    {
        path: '/gestione-abbonamenti',
        name: 'GestioneAbbonamenti',
        components: {
            navbar: Navbar_vue_1.default,
            default: function () { return Promise.resolve().then(function () { return require('@/views/Abbonamenti.vue'); }); },
            footer: Footer_vue_1.default
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/gestione-tessere-del-tifoso',
        name: 'GestioneTessereDelTifoso',
        components: {
            navbar: Navbar_vue_1.default,
            default: function () { return Promise.resolve().then(function () { return require('@/views/TessereDelTifoso.vue'); }); },
            footer: Footer_vue_1.default
        },
        meta: {
            requiresAuth: true
        }
    }
];

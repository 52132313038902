"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var costanti_1 = require("@/costanti");
exports.default = vue_1.default.extend({
    props: ['tipoNumero'],
    data: function () { return ({
        NUMERO_ABBONAMENTI: costanti_1.NUMERO_ABBONAMENTI, NUMERO_TESSERE_DEL_TIFOSO: costanti_1.NUMERO_TESSERE_DEL_TIFOSO
    }); },
    computed: __assign({}, vuex_1.mapState('numeriAttuali', ['numeroAttualeAbbonamenti', 'numeroAttualeTessereDelTifoso']), { coloreTitolo: function () {
            switch (this.tipoNumero) {
                case this.NUMERO_ABBONAMENTI:
                    return '#e74c3c';
                case this.NUMERO_TESSERE_DEL_TIFOSO:
                    return '#27ae60';
            }
        } }),
    methods: __assign({}, vuex_1.mapActions('modal', ['showModal']), vuex_1.mapActions('numeriAttuali', ['postNumeriAttuali']), vuex_1.mapMutations('modal', ['SET_TIPO_NUMERO']), { letteraVicina: function (lettera, successivo) {
            var lettere = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];
            var indexOfLettera = lettere.indexOf(lettera);
            if (successivo) {
                if (indexOfLettera === lettere.length - 1)
                    return lettere[0];
                else
                    return lettere[indexOfLettera + 1];
            }
            else {
                if (indexOfLettera === 0)
                    return lettere[lettere.length - 1];
                else
                    return lettere[indexOfLettera - 1];
            }
        },
        calcolaEInviaNumero: function (successivo) {
            var _a;
            var numeroAttuale = this.tipoNumero === this.NUMERO_ABBONAMENTI
                ? this.numeroAttualeAbbonamenti
                : this.numeroAttualeTessereDelTifoso;
            if (!numeroAttuale)
                return;
            var lettera = numeroAttuale[0].toUpperCase();
            var parteNumerica = parseInt(numeroAttuale.substring(1, 10));
            var prossimaParteNumerica = successivo ? parteNumerica + 1 : parteNumerica - 1;
            var parteNumericaInStringa;
            if (prossimaParteNumerica > 99) {
                parteNumericaInStringa = '00';
                lettera = this.letteraVicina(lettera, successivo);
            }
            else if (prossimaParteNumerica < 1) {
                parteNumericaInStringa = '99';
                lettera = this.letteraVicina(lettera, successivo);
            }
            else if (prossimaParteNumerica < 10)
                parteNumericaInStringa = '0' + prossimaParteNumerica;
            else
                parteNumericaInStringa = '' + prossimaParteNumerica;
            var risultato = lettera + parteNumericaInStringa;
            this.postNumeriAttuali((_a = {},
                _a[this.tipoNumero] = risultato,
                _a));
        },
        mostraModalSelezionaNumero: function () {
            this.SET_TIPO_NUMERO(this.tipoNumero);
            this.showModal('seleziona-numero');
        } }),
});

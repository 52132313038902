"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var SelezionaNumero_1 = require("./SelezionaNumero");
var NotificationAlert_1 = require("./NotificationAlert");
exports.default = vue_1.default.extend({
    components: {
        SelezionaNumero: SelezionaNumero_1.default,
        NotificationAlert: NotificationAlert_1.default
    },
    computed: __assign({}, vuex_1.mapState('modal', ['activeNotification', 'activeModal']))
});

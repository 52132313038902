"use strict";
/* ============
 * Vuex Store
 * ============
 *
 * Vuex is a state management pattern + library for Vue.js applications.
 * It serves as a centralized store for all the components in an application,
 * with rules ensuring that the state can only be mutated in a predictable fashion.
 *
 * http://vuex.vuejs.org/en/index.html
 */
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
vue_1.default.use(vuex_1.default);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vuex_persistedstate_1 = require("@/plugins/vuex-persistedstate");
var vuex_1 = require("vuex");
var auth_1 = require("./auth");
var modal_1 = require("./modal");
var numeri_attuali_1 = require("./numeri_attuali");
var user_1 = require("./user");
exports.default = new vuex_1.default.Store({
    plugins: [vuex_persistedstate_1.vuexUser, vuex_persistedstate_1.vuexToken],
    modules: {
        auth: auth_1.default,
        modal: modal_1.default,
        numeriAttuali: numeri_attuali_1.default,
        user: user_1.default
    }
});

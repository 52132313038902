"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Index_1 = require("@/components/modals/Index");
var vuex_1 = require("vuex");
exports.default = vue_1.default.extend({
    components: {
        ModalManager: Index_1.default
    },
    methods: __assign({}, vuex_1.mapActions('numeriAttuali', ['getNumeriAttuali']), vuex_1.mapActions('auth', ['checkForSavedToken'])),
    created: function () {
        setInterval(this.getNumeriAttuali, 2000);
        this.checkForSavedToken();
    }
});

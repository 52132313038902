import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=11de7ed4&scoped=true&lang=pug"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=11de7ed4&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11de7ed4",
  null
  
)

export default component.exports